import $ from 'jquery';

const PARAMS_DEFAULT = ['name', 'frequency', 'enabled', 'source', 'email', 'libraryid'];

export function constructFromForm(element) {
    const formData = $(element).serializeArray(); // TODO: We can use the built-in FormData when we get rid of IE

    const res = { search_params: {} };
    for (const item of formData) {
        if (PARAMS_DEFAULT.indexOf(item.name) !== -1) {
            res[item.name] = item.value;
        } else if (res.search_params[item.name] !== undefined) {
            if (!Array.isArray(res.search_params[item.name])) {
                res.search_params[item.name] = [res.search_params[item.name]];
            }
            res.search_params[item.name].push(item.value);
        } else {
            res.search_params[item.name] = item.value;
        }
    }

    // FIXME: Hack: only send the address if we also have a radius.
    // The address may be present for travel time purposes.
    if (!res.search_params.radius) {
        delete res.search_params.address;
        delete res.search_params.latitude;
        delete res.search_params.longitude;
    }

    if (typeof res.enabled === 'string') {
        res.enabled = parseInt(res.enabled, 10);
    }
    if (typeof res.enabled === 'number') {
        res.enabled = !!res.enabled;
    }

    if (res.libraryid) {
        res.id = res.libraryid;
        delete res.libraryid;
    }

    return res;
}
