import $ from 'jquery';

import ApiConflictError from "JIX/errors/ApiConflictError.js";
import ApiValidationError from "JIX/errors/ApiValidationError.js";

import { createJobagent } from 'JIX/jobagent/api.js';
import { constructFromForm } from 'JIX/jobagent/utils.js';

const stash = window.Stash['jobagent/popup'];

function jobmail_popup_ready() {
    $('#jobmail_popup.show-on-load').modal('show');
    $('#jobmail_popup').on('hide.bs.modal', function() {
        $('#jobmail_popup').find('[name=email]').val('');
        $.ajax({ url: '/api/popup/jobagent/closed' });
    });
}

window.jobmail_popup_ready = jobmail_popup_ready;

$(() => {
    jobmail_popup_ready();

    $(document).on('click', '.js-popup-agent', event => {
        event.preventDefault();

        const $form = $(stash.formSelector);

        const source = $(event.target).data('jobagent-source');
        const tag = $(event.target).data('jobagent-tag');
        const query = $form.find('[name=q]').val();

        if (!source) {
            console.error('jobagent popup opened without a source', event.target);
        }

        $form.find('[name=source]').val(source);
        if ($form.find('[name=email]').val()) {
            window.jixAnalytics('event', { category: 'Jobagent', action: tag, label: query });
            $form.trigger('submit');
        } else {
            window.jixAnalytics('event', { category: 'Jobagent', action: tag, label: query });
            $form.find("[name='analytics_tag']").val('popup');
            $('#jobmail_popup').modal('show');
        }
    });

    $(document).on('submit', stash.formSelector, event => {
        event.preventDefault();

        const $form = $(stash.formSelector);
        const analytics_tag = $form.find("[name='analytics_tag']").val();
        const query = $form.find('[name=q]').val();

        $form.find('.invalid-feedback').remove();
        $form.find('.is-invalid').removeClass('is-invalid');

        if (!window.Stash.common.userid) {
            window.jixAnalytics('event', { category: 'Jobagent', action: analytics_tag, label: query });
        }

        const jobagent = constructFromForm($form[0]);

        createJobagent(jobagent)
            .then((res) => {
                location.href = res.redirect_url;
            })
            .catch((error) => {
                if (error instanceof ApiConflictError && error.error === 'duplicate_jobagent') {
                    location.href = error.extraData.redirect_url;
                } else if (error instanceof ApiValidationError) {
                    const $email = $form.find('[name=email]');
                    $email.addClass('is-invalid');
                    for (const fieldError of error.errors) {
                        $email.parent().append($('<div class="invalid-feedback">').text(fieldError.error_message));
                    }
                } else {
                    throw error;
                }
            });
    });
});
