import { makeApi } from 'JIX/utils.js';

const API_VERSION = 1;
const api = makeApi(`/api/user/jobagent/v${API_VERSION}/`, { json: true });

export function updateJobagent(jobagent) {
    return api('PATCH', [jobagent.id], { jobagent });
}

export function setJobagentState(jobagent, enabled) {
    const newJobagent = { ...jobagent, enabled };
    return updateJobagent(newJobagent);
}

export function deleteJobagent(jobagent) {
    return api('DELETE', [jobagent.id]);
}

export function createJobagent(jobagent) {
    return api('POST', [], { jobagent });
}
